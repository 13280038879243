<template>
    <div class="code-page">
        <div class="code-options">
            <b-form-radio-group
                v-model="selectedCodeOption"
                :options="getCodeOptions"
                value-field="item"
                text-field="name"
                class="mb-1"
            ></b-form-radio-group>
        </div>
        <div class="code-inject-block" v-for="codeType in codeTypes" :key="codeType">
            <b-button
                class="show-desciption"
                @click="showDescription = true"
                v-if="(codeType == 'js' && selectedCodeOption == codeType)"
                variant="outline-warning"
            >Description</b-button>
            <codemirror
                v-if="selectedCodeOption == codeType"
                v-model="$data[`injectedCode_${codeType}`]"
                :options="getCoMiOptions"
            ></codemirror>
        </div>

        <div class="javascript-description" v-if="showDescription" v-click-outside="hideDescription">
            <div>
                <h4>You are available to use next variables inside the code editor:</h4>
                <br>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/index.html#manual/en/introduction/Creating-a-scene">this.THREE</a> - Threejs library imported as THREE</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/index.html?q=scene#api/en/scenes/Scene">this.Scene</a> - The Scene of current Editor</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/index.html?q=orbi#examples/en/controls/OrbitControls">this.Controls</a> - Orbit controls object</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/index.html?q=render#api/en/constants/Renderer">this.Renderer</a> - Threejs WebGLRenderer</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/index.html?q=camera#api/en/cameras/Camera">this.Camera</a> - Threejs PerspectiveCamera</p>
                <p><a class="code-style" target="_blank" href="https://github.com/gkjohnson/three-mesh-bvh">this.ThreeMeshBVH</a> - Full Three mesh BVH implementation (import * as ThreeMeshBVH)</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/#manual/en/introduction/How-to-use-post-processing">this.ShaderPass</a> - Custom post-processing shader</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/#examples/en/postprocessing/EffectComposer">this.EffectComposer</a> - Used to implement post-processing effects</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/#manual/en/introduction/How-to-use-post-processing">this.GammaCorrectionShader</a> - Needed when using the EffectComposer</p>
                <p><a class="code-style" target="_blank" href="https://threejs.org/docs/#manual/en/introduction/How-to-use-post-processing">this.SMAAPass</a> - Custom post-processing shader</p>
                <p>this.ReflectorPlaneVertical - Vertical model reflection</p>
                <p>this.ReflectorPlaneHorizontal - Horizontal model reflection</p>

                <p>See more information in 
                    <a
                        style="color: blue" 
                        target="_blank"
                        href="https://threejs.org/docs/index.html#manual/en/introduction/Creating-a-scene"
                    >
                        Three.js
                    </a>
                    official documentation
                </p>
            </div>
        </div>

        <!-- END CodeMirror -->

        <div class="d-flex justify-content-center">
          <b-button
            @click="onSubmit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="busyButton"
            style="margin-top: 15px"
          >
            Submit
          </b-button>
        </div>

        <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
          <template #overlay>
            <div
              v-if="processing"
              class="text-center p-3 bg-primary text-light rounded"
            >
              <feather-icon icon="UploadCloudIcon" size="20" />
              <div class="mb-2">Preservation...</div>
              <b-progress
                min="1"
                max="5"
                :value="counter"
                variant="success"
                height="6px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <b-card-text class="font-weight-bolder">
                Are you sure?
              </b-card-text>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
    </div>
</template>

<script>
import { BFormRadioGroup } from "bootstrap-vue"
import { errorMessage } from "@/auth/utils"
import { codemirror } from "vue-codemirror"
import "codemirror/mode/javascript/javascript.js"
import "codemirror/mode/xml/xml.js"
import "codemirror/mode/css/css.js"
import "codemirror/lib/codemirror.css"
import "codemirror/theme/ayu-dark.css"
import "codemirror/theme/neo.css"


export default {
    name: "code-injection",
    components: {
        BFormRadioGroup,
        codemirror,
    },
    data() {
        return {
            codeTypes: ["html", "js", "css"],
            selectedCodeOption: "html",
            injectedCode_html: "",
            injectedCode_js: "",
            injectedCode_css: "",
            busyButton: true,
            editorIsLoaded: false,
            busy: false,
            processing: false,
            interval: null,
            counter: 1,
            showDescription: false,
        }
    },
    computed: {
        getCodeOptions() {
            return this.codeTypes.map(n => ({item: n, name: n.toUpperCase()}))
        },
        getCoMiOptions() {
            return ({
                tabSize: 4,
                mode: this.getCoMiMode,
                theme: this.getCoMiTheme,
                lineNumbers: true,
                htmlMode: true,
            })
        },
        getCoMiTheme() {
            if (this.$store.state.appConfig.layout.skin === "dark") { 
                return "ayu-dark" 
            } else {
                return "neo"
            }
        },
        getCoMiMode() {
            let codeMirrorModes = { html: "application/xml", css: "css", js: "text/javascript"}
            return codeMirrorModes[this.selectedCodeOption]
        },
    },
    methods: {
        hideDescription() {
            this.showDescription = false
        },
        onSubmit() {
            if (this.editorIsLoaded) {
                this.processing = false
                this.busy = true
                this.busyButton = true
            }
        },
        onCancel() {
            this.busy = false;
            this.busyButton = false
        },
        onOK() {
            this.processing = true;
            this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
            this.counter = 1;

            let codeDictionary = {
                injected_code_html: this.injectedCode_html,
                injected_code_js: this.injectedCode_js,
                injected_code_css: this.injectedCode_css,
            }
            this.$store.dispatch('Editor/updateInjectedCode', codeDictionary).then(() => {
                this.updateProcessing();
                this.busyButton = false
            })
            .catch((error) => {
                errorMessage(this.$toast, error);
            });
        },
        updateProcessing() {
            // Counter is going up from 1 to 5
            // Simulate an async request
            this.clearInterval();
            this.interval = setInterval(() => {
                if (this.counter < 5) {
                this.counter += 1;
                } else {
                this.clearInterval();
                this.$nextTick(() => {
                    // eslint-disable-next-line
                    this.busy = this.processing = false;
                });
                }
            }, 350);
        },
        async loadEditorAsync() {
            await this.$store.dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
                .then(() => {
                    this.initInjectedCodeData()
                    this.editorIsLoaded = true
                    this.busyButton = false
                })
                .catch((error) => {
                    errorMessage(this.$toast, error);
                }
            );
        },
        clearInterval() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
        initInjectedCodeData() {
            let editor = this.$store.getters["Editor/getEditor"]
            this.injectedCode_html = editor.injected_code_html ? editor.injected_code_html : ""
            this.injectedCode_js = editor.injected_code_js ? editor.injected_code_js : ""
            this.injectedCode_css = editor.injected_code_css ? editor.injected_code_css : ""
        },
    },
    mounted() {
        this.loadEditorAsync()
    },
    beforeDestroy() {
        this.clearInterval();
    },
}
</script>

<style lang="scss">
.code-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .code-inject-block {
        width: 80%;
        .show-desciption {
            position: absolute;
            right: 13%;
            top: 150px;
            z-index: 10;
        }
    }
}
.CodeMirror {
    height: 70vh;
    pre.CodeMirror-line {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}
.javascript-description {
    position: fixed;
    z-index: 11;
    width: 700px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(248, 248, 248, 0.8);
    color: black;
    border: 1px solid rgba(40, 39, 39, 0.8);
    border-radius: 10px;
    
    h4 {
        color: #5e5873;
    }
}
.code-style {
    color: rgb(29, 70, 192) !important;
}
</style>
